import React from "react";
import Navbar from "../components/Navbar";

export default function Error(){
    return(
    <>
    <Navbar/>
    <h4 className="px-4">Page not found Error</h4>
    </>
        
    )
}